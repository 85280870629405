import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Button, ButtonContainer } from "elaspark-ui";
import "../ModalsPage/ModalsPage.css";
const CodeBlockLazy = React.lazy(() => import("../../components/CodeBlock"));

const ButtonAccessibility = () => {
  const [showCode, setShowCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowCode = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCode(true);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Accessible Buttons - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/buttons/button-accesibility"/>
        <meta
          name="description"
          content="Discover Ela Spark UI's accessible buttons, designed for inclusivity and compliance with ARIA standards."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, accessible buttons, ARIA standards, inclusive design, web accessibility, React components, UI library, button accessibility, user-friendly interfaces, accessible web applications"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/buttons/button-accesibility"
        />
        <meta property="og:title" content="Accessible Buttons - Ela Spark UI" />
        <meta
          property="og:description"
          content="Discover Ela Spark UI's accessible buttons, designed for inclusivity and compliance with ARIA standards."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/buttons/button-accesibility"
        />
        <meta
          property="twitter:title"
          content="Accessible Buttons - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Discover Ela Spark UI's accessible buttons, designed for inclusivity and compliance with ARIA standards."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Accessibility Features in Ela Spark UI Buttons</h1>
        <p>
          Ela Spark UI Button ensures that each button is not only functional
          and stylish but also accessible to all users, including those with
          disabilities. Our comprehensive accessibility features help create an
          inclusive user experience.
        </p>
      </div>

      {/* How to Use Section */}
      <div className="prop-container">
        <h2>Comprehensive Accessibility Features</h2>
        <p>
          Our buttons are equipped with a range of accessibility features,
          making them user-friendly and compliant with ARIA standards:
        </p>
        <div className="prop-description">
          <p>
            <strong>aria-label:</strong> Provides a clear, descriptive label for
            screen readers.
          </p>
          <p>
            <strong>aria-pressed:</strong> Indicates the state of toggle buttons
            for assistive technologies.
          </p>
          <p>
            <strong>aria-disabled:</strong> Communicates the disabled state of
            the button to screen readers.
          </p>
          <p>
            <strong>aria-expanded:</strong> Indicates whether the button
            controls the expansion of another element.
          </p>
          <p>
            <strong>aria-haspopup:</strong> Signifies that the button triggers a
            popup, such as a menu.
          </p>
          <p>
            <strong>aria-controls:</strong> References the ID of the element
            controlled by the button.
          </p>
          <p>
            <strong>role:</strong> Defines the button role for accessibility
            purposes.
          </p>
          <p>
            <strong>tabIndex:</strong> Manages the button's position in the
            keyboard navigation order.
          </p>
        </div>
        <p>
          These props are instrumental in creating a fully accessible web
          application, ensuring all users can interact with your buttons
          effectively.
        </p>
      </div>

      {/* Code Examples and Demonstrations */}
      <div className="text-container">
        <h2>Accessible Button Example</h2>
        <p>
          Below is an example demonstrating the use of these accessibility
          features in a real-world scenario.
        </p>
      </div>
      <div className="modal-code-examples">
        <div className="button-code-examples">
          <h2>Example: Fully Accessible Button</h2>
          <ButtonContainer align="center">
            <Button onClick={handleShowCode}>
              {loading ? "Chargement..." : "Show Code"}
            </Button>
          </ButtonContainer>

          {showCode && (
            <Suspense fallback={<div>Chargement...</div>}>
              <CodeBlockLazy>
                {`<Button
  aria-label="More Options"
  aria-haspopup="true"
  aria-controls="options-menu"
  onClick={handleButtonClick}
  styleType="btn-1"
  animation="wave"
>
  More Options
</Button>`}
              </CodeBlockLazy>
            </Suspense>
          )}
        </div>
      </div>
      {/* Additional Accessibility Features */}
      <div className="text-container">
        <h2>Building Towards Inclusive Design</h2>
        <p>
          At Ela Spark UI Button, we're committed to fostering an inclusive
          digital environment. Our button components are a testament to this
          commitment, offering advanced accessibility features that cater to a
          diverse audience. We continue to evolve our components to meet the
          highest standards of accessibility.
        </p>
      </div>
    </div>
  );
};

export default ButtonAccessibility;