import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import CodeBlock from "../../components/CodeBlock";

import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homeContainer">
      <Helmet>
        <title>Elevate Your UI with Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev"/>
        <meta
          name="description"
          content="Unveil the power of intuitive and customizable modals and buttons with Ela Spark UI Modals."
        />
        <meta
          name="keywords"
          content="react, modal, button, ui, library, component, React UI library, Customizable modals,Interactive buttons,JavaScript UI components,Responsive modal design,React button styles,Modal window library,Frontend web development,Dynamic UI elements,Accessible modals,Accessible modals,React component customization,Modern web components,Lightweight UI toolkit,Easy-to-use modals,React dialog component,Flexible UI framework,UI/UX design React,Animation in React components,Scalable frontend library,React overlay components,CSS styling React,Interactive user interface,Web app UI design,Modular React components,React state management,Efficient UI development,React popup library,Tailored button design,Advanced modal functionality,Custom React UI,Modern frontend techniques,React accessibility features,Web development best practices,Reactjs UI components,Developer-friendly UI kit,Creative UI solutions,High-performance modals,Seamless UI integration"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.elaspark-ui.dev" />
        <meta property="og:title" content="Elevate Your UI with Ela Spark UI" />
        <meta
          property="og:description"
          content="Unveil the power of intuitive and customizable modals and buttons with Ela Spark UI Modals."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.elaspark-ui.dev" />
        <meta
          property="twitter:title"
          content="Elevate Your UI with Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Unveil the power of intuitive and customizable modals and buttons with Ela Spark UI Modals."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      <main className="home-main">
        <div className="home-pub">
          {/* Introduction Section */}
          <section className="home-intro">
            <h1>Elevate Your UI with Ela Spark UI</h1>
            <p>
              Unveil the power of intuitive and customizable modals and buttons
              with Ela Spark UI Modals. Transform your user experience with
              minimal effort and maximal impact.
            </p>
            <Link
              to="/modals/modal-essentials"
              className="cta-button grow-on-hover slide-up"
            >
              Get Started
            </Link>
          </section>

          <section className="home-how-it-works slide-up">
            <h2>Seamless Integration, Effortless Customization</h2>
            <div className="step-by-step">
              <div className="step">
                <h3>1. Select and Style</h3>
                <p>
                  Choose from a variety of modals and tailor them to fit your
                  project’s aesthetics.
                </p>
              </div>
              <div className="step fade-in">
                <h3>2. Customize with Ease</h3>
                <p>
                  Adapt and fine-tune modal properties to align with your
                  branding effortlessly.
                </p>
              </div>
              <div className="step">
                <h3>3. Integrate with Simplicity</h3>
                <p>
                  Embed modals into your web project seamlessly with minimal
                  coding effort.
                </p>
              </div>
            </div>
          </section>

          {/* Installation and Import Section */}
          <section className="home-installation slide-up">
            <h2>Getting Started with Ela Spark UI</h2>
            <p>
              Jumpstart your project with ElaSpark-UI. Follow these simple steps
              to install and use our customizable modals and buttons.
            </p>
            <div className="installation-guide">
              <h3>Effortless Installation</h3>
              <CodeBlock>{`npm install elaspark-ui`}</CodeBlock>

              <h3>Seamlessly Integrating Modal Component</h3>
              <CodeBlock>{`import { Modal } from 'elaspark-ui';`}</CodeBlock>
              <div className="prop-description fade-in">
                <p>
                  Embrace the versatility of <strong>Modal</strong> components,
                  offering seamless integration for dynamic dialog creation.
                </p>
                <p>
                  Enjoy a plethora of customization options, from size and style
                  to animations and accessibility.
                </p>
              </div>

              <h3>Empowering Your UI with Button Component</h3>
              <CodeBlock>{`import { Button } from 'elaspark-ui';`}</CodeBlock>
              <div className="prop-description fade-in">
                <p>
                  Transform user interactions with <strong>Button</strong>{" "}
                  components, designed for engagement and style.
                </p>
                <p>
                  Tailor your buttons with a range of animations, styles, and
                  responsive designs to enhance accessibility and user
                  experience.
                </p>
              </div>
            </div>
          </section>

          <section className="home-gallery">
            <h2>A Canvas of Possibilities</h2>
            <p>
              Get inspired by a diverse gallery of modals, each demonstrating
              the flexibility of Ela Spark UI Modals.
            </p>
            <div className="modal-examples">
              {/* Pictures or Modal components showcasing variety */}
            </div>
          </section>

          <section className="home-get-started">
            <h2>Embark on Your Modal Journey</h2>
            <p>
              Explore our comprehensive guides and documentation to kickstart
              your journey with Ela Spark UI.
            </p>
            <Link to="/modals/modal-essentials" className="cta-button">
              Explore the Documentation
            </Link>
          </section>
        </div>

        {/* Modals and Buttons Section */}
        
        {/* Conclusion */}
        <section className="home-conclusion">
          <h2 className="align">Join the Ela Spark UI Evolution</h2>
          <p className="align">
            Be part of a community that's reshaping the digital landscape. With
            Ela Spark UI, every modal and button becomes a journey of discovery.
            Start creating with confidence today.
          </p>
        </section>
      </main>
    </div>
  );
};

export default HomePage;