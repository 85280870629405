import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.webp";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="home-footer footer-neon-shadow">
      <div className="footer-content">
        <img src={logo} alt="Ela Spark UI Logo" className="footer-logo" />

        <div className="footer-links">
          <div className="footer-column">
            <h4>Quick Links</h4>
            <Link to="/">Home</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>

          <div className="footer-column">
            <h4>Community</h4>
            <a
              href="https://github.com/Stephane-OC/ElaSpark-UI"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://patreon.com/user?u=113383984"
              target="_blank"
              rel="noopener noreferrer"
            >
              Patreon
            </a>
          </div>
        </div>
        <p>&copy; 2023 - {currentYear} Ela Spark UI. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;