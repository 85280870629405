import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as ChevronDownIcon } from "../../assets/img/chevron-down-solid.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/img/chevron-right-solid.svg";
import "./SideBar.css";

function SideBar({
  isSidebarOpen,
  onToggleModals,
  onToggleButtons,
  isModalsOpen,
  isButtonsOpen,
}) {
  return (
    <aside className={`sidebar ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}>
      <div className="sidebar-item">
        <div onClick={onToggleModals} className="sidebar-title">
          Modals
          {isModalsOpen ? (
            <ChevronDownIcon className="icon" />
          ) : (
            <ChevronRightIcon className="icon" />
          )}
        </div>
        {isModalsOpen && (
          <ul className="sidebar-dropdown">
            <li>
              <NavLink
                to="/modals/modal-essentials"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Modal Essentials
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/modals/modal-styles"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Modal Styles
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/modals/modal-backgrounds"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Modal Backgrounds
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/modals/modal-animations"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Modal Animations
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/modals/modal-accessibility"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Modal Accessibility
              </NavLink>
            </li>
          </ul>
        )}
      </div>
      <div className="sidebar-space">
        <div onClick={onToggleButtons} className="sidebar-title">
          Buttons
          {isButtonsOpen ? (
            <ChevronDownIcon className="icon" />
          ) : (
            <ChevronRightIcon className="icon" />
          )}
        </div>
        {isButtonsOpen && (
          <ul className="sidebar-dropdown">
            <li>
              <NavLink
                to="/buttons/button-essencials"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Button Essencials
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buttons/button-styles"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Button Styles
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buttons/button-borders"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Button Borders
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buttons/button-animations"
                className={(navData) => (navData.isActive ? "active" : "")}
              >
                Button Animations
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/buttons/button-accesibility"
                activeclassname="active"
              >
                Button Accesibility
              </NavLink>
            </li>
          </ul>
        )}
       
      </div>
    </aside>
  );
}

export default SideBar;