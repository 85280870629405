const faqData = [
  {
    question: "How to Install Ela Spark UI?",
    answer: "You can install Ela Spark UI using npm: npm install elaspark-ui.",
  },
  {
    question: "How to Customize Modals in Ela Spark UI?",
    answer:
      "Modals in Ela Spark UI are highly customizable. You can modify their size, style, and animation through props.",
  },
  {
    question: "Are Ela Spark UI Modals Accessible?",
    answer:
      "Yes, accessibility is a priority. Our modals support ARIA attributes and keyboard navigation for inclusivity.",
  },
  {
    question: "Can I Use Custom Animations with Ela Spark UI Modals?",
    answer:
      "Absolutely! Ela Spark UI allows you to apply custom animations to modals to enhance user experience.",
  },
  {
    question: "Is Ela Spark UI Responsive for Mobile Devices?",
    answer:
      "Yes, Ela Spark UI components are designed to be fully responsive, ensuring a seamless experience across devices.",
  },
  {
    question: "How to Integrate Ela Spark UI with React?",
    answer:
      "Integration is straightforward. After installation, import the components you need from Ela Spark UI into your React project.",
  },
  {
    question: "Does Ela Spark UI Support Theming?",
    answer:
      "Ela Spark UI supports theming, allowing you to align the components with your brand's visual identity.",
  },
  {
    question: "Are There Examples of Ela Spark UI in Use?",
    answer:
      "Yes, our documentation includes examples to help you visualize how Ela Spark UI can be utilized in projects.",
  },
  {
    question: "Can I Contribute to Ela Spark UI?",
    answer:
      "Certainly! We welcome contributions. You can contribute through GitHub by submitting pull requests or reporting issues.",
  },
  {
    question: "How to Create a Button with Ela Spark UI?",
    answer:
      "Creating a button is easy. Simply use the Button component and customize it with various style and animation props.",
  },
  {
    question: "Is There a Dark Mode in Ela Spark UI?",
    answer:
      "Yes, Ela Spark UI supports dark mode, which can be enabled for modals and buttons to match your dark-themed UI.",
  },
  {
    question: "How Do I Report a Bug in Ela Spark UI?",
    answer:
      "Bugs can be reported on our GitHub repository's issue tracker. We appreciate your help in improving Ela Spark UI.",
  },
  {
    question: "How to Update Ela Spark UI?",
    answer:
      "To update Ela Spark UI, simply run npm update elaspark-ui in your project directory.",
  },
  {
    question: "Does Ela Spark UI Have a Community Forum?",
    answer:
      "Currently, we do not have a dedicated forum, but you can join discussions on our GitHub repository.",
  },
  {
    question: "How can I support Ela Spark UI on Patreon?",
    answer:
      "Join our community on Patreon. By becoming a patron, you assist us in maintaining and enhancing the project. You'll gain access to exclusive content and updates. Visit our Patreon page for more information.",
  },
  {
    question: "How can I provide feedback and suggestions for Ela Spark UI?",
    answer:
      "Your feedback is invaluable. If you have suggestions or comments, please feel free to share them. This helps us understand user needs and guide the project's development.",
  },
  {
    question: "How can I help spread the word about Ela Spark UI?",
    answer:
      "Sharing the project on social media, in developer groups, or with colleagues can greatly help in its growth. Every share counts and helps us reach a wider audience.",
  },
];

export default faqData;
