import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ModalExample from "../../components/ModalExample";
import "./ModalsPage.css";

const ModalBackgrounds = () => {
  const [backdropStyle, setBackdropStyle] = useState("dark-opaque");

  return (
    <div className="home-container">
      <Helmet>
        <title>Modal Backdrops - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/modals/modal-backgrounds"/>
        <meta
          name="description"
          content="Explore a variety of backdrop styles with Ela Spark UI Modals, enhancing your modals with different aesthetic tones and moods."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, modal backgrounds, backdrop styles, UI design, modal aesthetics, web design, React modals, dynamic backgrounds, modal customization, creative backdrops, modal themes, UI/UX design, frontend development, interactive modals, modal design"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/modals/modal-backgrounds"
        />
        <meta property="og:title" content="Modal Backdrops - Ela Spark UI" />
        <meta
          property="og:description"
          content="Explore a variety of backdrop styles with Ela Spark UI Modals, enhancing your modals with different aesthetic tones and moods."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/modals/modal-backgrounds"
        />
        <meta
          property="twitter:title"
          content="Modal Backdrops - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Explore a variety of backdrop styles with Ela Spark UI Modals, enhancing your modals with different aesthetic tones and moods."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Enhance Your Modals with Versatile Backgrounds</h1>
        <p>
          Ela Spark UI Modals offers a range of backdrop styles to add depth and
          character to your modals. From subtle to bold, find the perfect
          backdrop to complement your modal's content.
        </p>
      </div>

      {/* Backdrop Options Overview */}
      <div className="prop-container">
        <h2>Customizing Your Modal Backdrops</h2>
        <p>
          Select from various backdrop styles to set the tone of your modals.
          Each style adds a unique aesthetic, from dark and moody to bright and
          cheerful.
        </p>
        <div className="prop-description">
          <h3>Available Backdrop Styles</h3>
          <p>
            <strong>dark-opaque:</strong> A deep, dark background for a dramatic
            effect.
          </p>
          <p>
            <strong>light-opaque:</strong> A light, airy background for a softer
            touch.
          </p>
          <p>
            <strong>indigo:</strong> A vibrant indigo background for a modern
            feel.
          </p>
          <p>
            <strong>red:</strong> A bold red backdrop for a striking appearance.
          </p>
          <p>
            <strong>green:</strong> A soothing green background for a natural
            look.
          </p>
          <p>
            <strong>blue-light:</strong> A soft blue backdrop for a calming
            effect.
          </p>
          <p>
            <strong>pink-light:</strong> A gentle pink background for a touch of
            warmth.
          </p>
          <p>
            <strong>yellow-light:</strong> A cheerful yellow backdrop to
            brighten the mood.
          </p>
          <p>
            <strong>purple:</strong> A sophisticated purple background for an
            elegant presentation.
          </p>
          <p>
            ... and other unique styles to match your modal's theme and content.
          </p>
        </div>
      </div>
      {/* Interactive Backdrop Example */}
      <div>
        <div className="text-container">
          <h2>Try Out Different Backdrops</h2>
          <p>
            Use the input below to test different backdrop styles in real-time.
            See how each backdrop can change the look and feel of your modal.
          </p>
        </div>
        <ModalExample
          titleText="Backdrop Styles"
          inputType="backdropStyle"
          defaultValue={backdropStyle}
          onChange={(e) => setBackdropStyle(e.target.value)}
          modalProp="backdropStyle"
          modalPropValue={backdropStyle}
        />
      </div>

      {/* Conclusion and Encouragement to Explore */}
      <div className="text-container">
        <h2>Explore and Experiment</h2>
        <p>
          Ela Spark UI Modals gives you the creative freedom to experiment with
          various backdrop styles. Discover the perfect backdrop to enhance your
          modal's aesthetic and create an immersive user experience.
        </p>
      </div>
    </div>
  );
};

export default ModalBackgrounds;