import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SideBar from "./components/SideBar/SideBar";

import HomePage from "./pages/HomePage/HomePage";

import ModalEssentials from "./pages/ModalsPage/ModalEssencials";
import ModalAccessibility from "./pages/ModalsPage/ModalAccessibility";
import ModalAnimations from "./pages/ModalsPage/ModalAnimations";
import ModalBackgrounds from "./pages/ModalsPage/ModalBackgrounds";
import ModalStyles from "./pages/ModalsPage/ModalStyles";

import ButtonEssencials from "./pages/ButtonsPage/ButtonEssencials";
import ButtonsAccesibility from "./pages/ButtonsPage/ButtonAccesibility";
import ButtonsAnimations from "./pages/ButtonsPage/ButtonAnimations";
import ButtonBorders from "./pages/ButtonsPage/ButtonBorders";
import ButtonStyle from "./pages/ButtonsPage/ButtonStyle";


import FAQ from "./pages/FAQ/FAQ";
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";

import "./App.css";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isModalsOpen, setIsModalsOpen] = useState(true);
  const [isButtonsOpen, setIsButtonsOpen] = useState(true);

  const toggleSidebar = () =>
    setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  const toggleModals = () => setIsModalsOpen(!isModalsOpen);
  const toggleButtons = () => setIsButtonsOpen(!isButtonsOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 767) {
        setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Handle clicks outside of the sidebar to close it on mobile
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".sidebar") &&
        !event.target.closest(".sidebar-toggle-button") &&
        window.innerWidth < 767
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Router basename="/">
      <Header onToggleSidebar={toggleSidebar} />
      <SideBar
        isSidebarOpen={isSidebarOpen}
        onToggleModals={toggleModals}
        onToggleButtons={toggleButtons}
        isModalsOpen={isModalsOpen}
        isButtonsOpen={isButtonsOpen}
      />
      <div className="main-layout">
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/modals/modal-accessibility"
              element={<ModalAccessibility />}
            />
            <Route
              path="/modals/modal-essentials"
              element={<ModalEssentials />}
            />
            <Route
              path="/modals/modal-animations"
              element={<ModalAnimations />}
            />
            <Route
              path="/modals/modal-backgrounds"
              element={<ModalBackgrounds />}
            />
            <Route path="/modals/modal-styles" element={<ModalStyles />} />
            <Route
              path="/buttons/button-accesibility"
              element={<ButtonsAccesibility />}
            />
            <Route
              path="/buttons/button-animations"
              element={<ButtonsAnimations />}
            />
            <Route path="/buttons/button-borders" element={<ButtonBorders />} />
            <Route path="/buttons/button-styles" element={<ButtonStyle />} />
            <Route
              path="/buttons/button-essencials"
              element={<ButtonEssencials />}
            />
            
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
      <Footer />
    </Router>
  );
}
export default App;