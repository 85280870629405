import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ModalExample from "../../components/ModalExample";
import "./ModalsPage.css";

const ModalStyles = () => {
  const [styleType, setStyleType] = useState("style-1");

  return (
    <div className="home-container">
      <Helmet>
        <title>Modal Styles - Ela Spark UI</title>
        <link
          rel="canonical"
          href="http://www.elaspark-ui.dev/modals/modal-styles"
        />
        <meta
          name="description"
          content="Explore a variety of stylish modal designs with Ela Spark UI Modals, perfect for enhancing your UI with unique and engaging looks."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, modal styles, UI design, stylish modals, creative modal designs, React modals, modern UI, modal customization, user experience, frontend development, UI/UX design, interactive modals, modal themes, modal aesthetics"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/modals/modal-styles"
        />
        <meta property="og:title" content="Modal Styles - Ela Spark UI" />
        <meta
          property="og:description"
          content="Explore a variety of stylish modal designs with Ela Spark UI Modals, perfect for enhancing your UI with unique and engaging looks."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/modals/modal-styles"
        />
        <meta property="twitter:title" content="Modal Styles - Ela Spark UI" />
        <meta
          property="twitter:description"
          content="Explore a variety of stylish modal designs with Ela Spark UI Modals, perfect for enhancing your UI with unique and engaging looks."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Elevate Your UI with Stylish Modals</h1>
        <p>
          Discover the power of Ela Spark UI Modals to transform your modals
          with a variety of style types. From minimalist to bold designs, find
          the perfect style that aligns with your brand and enhances user
          experience.
        </p>
      </div>

      {/* Style Options Overview */}
      <div className="prop-container">
        <h2>Styling Your Modals</h2>
        <p>
          Explore a diverse range of pre-designed modal styles. Each style is
          crafted to offer a unique look and feel, ensuring your modals stand
          out and engage users effectively.
        </p>
        <div className="prop-description slide-up">
          <h3>Available Modal Styles</h3>
          <p>
            <strong>style-1 :</strong> Classic design with a soft shadow, ideal
            for a sophisticated look.
          </p>
          <p>
            <strong>style-2 :</strong> Bold, modern with a colorful outline for
            standout appeal.
          </p>
          <p>
            <strong>style-3 :</strong> Subtle border with a light gray
            background for understated elegance.
          </p>
          <p>
            <strong>style-4 :</strong> Dark, sophisticated theme with a sleek
            appearance.
          </p>
          <p>
            <strong>style-5 :</strong> Bright, refined look with a gentle
            shadow.
          </p>
          <p>
            <strong>style-6 :</strong> Dark elegance with contrasting text for a
            bold statement.
          </p>
          <p>
            <strong>style-7 :</strong> Lively gradient background with a splash
            of color.
          </p>
          <p>
            <strong>style-8 :</strong> Minimalist blue backdrop for a sharp,
            focused look.
          </p>
          <p>
            <strong>style-9 :</strong> Clean, white background with soft
            elegance.
          </p>
          <p>
            <strong>style-10 :</strong> Dark backdrop with a vibrant colored
            border.
          </p>
          <p>
            <strong>style-11 :</strong> Light backdrop with a delicate, colored
            border.
          </p>
          <p>
            <strong>style-12 :</strong> Rich teal green for a contemporary feel.
          </p>
          <p>
            <strong>style-13:</strong> Deep indigo for a regal and distinguished
            appearance.
          </p>
        </div>
      </div>

      {/* Interactive Style Example */}
      <div>
        <div className="text-container">
          <h2>Experiment with Different Styles</h2>
          <p>
            Try out various modal styles using the input below. Observe in
            real-time how each style changes the modal's appearance.
          </p>
        </div>
        <ModalExample
          titleText="Modal Styles"
          inputType="styleType"
          defaultValue={styleType}
          onChange={(e) => setStyleType(e.target.value)}
          modalProp="styleType"
          modalPropValue={styleType}
        />
      </div>

      {/* Conclusion and Encouragement to Explore */}
      <div className="text-container">
        <h2 className="fade-in">Unleash Your Creativity</h2>
        <p>
          With Ela Spark UI Modals, the possibilities are endless. Dive into our
          range of styles and find the perfect match to elevate your
          application's design and user experience.
        </p>
      </div>
    </div>
  );
};

export default ModalStyles;