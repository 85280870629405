import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Button, ButtonContainer } from "elaspark-ui";
import "./ModalsPage.css";

const CodeBlockLazy = React.lazy(() => import("../../components/CodeBlock"));

const ModalAccessibility = () => {
  const [showCode, setShowCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowCode = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCode(true);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Accessible Modals - Ela Spark UI</title>
        <link rel="canonical" href="https://elaspark-ui.dev/modals/modal-accessibility" />
        <meta
          name="description"
          content="Ela Spark UI Modals prioritizes accessibility, ensuring content is available to everyone, including users with disabilities, in compliance with ARIA standards."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, accessible modals, web accessibility, ARIA standards, inclusive design, React modals, screen reader friendly, assistive technologies, modal accessibility, user-friendly modals, web design, UI components, frontend development, modal dialog, accessible web applications"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="https://elaspark-ui.dev/modals/modal-accessibility"
        />
        <meta property="og:title" content="Accessible Modals - Ela Spark UI" />
        <meta
          property="og:description"
          content="Ela Spark UI Modals prioritizes accessibility, ensuring content is available to everyone, including users with disabilities, in compliance with ARIA standards."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="https://elaspark-ui.dev/modals/modal-accessibility"
        />
        <meta
          property="twitter:title"
          content="Accessible Modals - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Ela Spark UI Modals prioritizes accessibility, ensuring content is available to everyone, including users with disabilities, in compliance with ARIA standards."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1 className="fade-in">
          Accessibility in Focus with Ela Spark UI Modals
        </h1>
        <p className="slide-up">
          In the world of web development, accessibility is crucial. Ela Spark
          UI Modals is designed to make your content accessible to everyone,
          including users with disabilities. We simplify accessibility in modals
          through intuitive props and compliance with the latest ARIA standards.
        </p>
      </div>

      {/* How to Use Section */}
      <div className="prop-container">
        <h2>Accessibility Simplified</h2>
        <p>
          Implementing accessible modals is straightforward with Ela Spark UI
          Modals. Here's how you can make your modals compliant and
          user-friendly:
        </p>
        <div className="prop-description">
          <p>
            <strong>role:</strong> "dialog" - This ARIA role informs assistive
            technologies that your modal is a dialog window.
          </p>
          <p>
            <strong>aria-labelledby:</strong> Reference your modal title's ID,
            enabling screen readers to recognize and announce the modal title.
          </p>
          <p>
            <strong>aria-describedby:</strong> Link to your modal's descriptive
            text ID, providing essential context to screen reader users.
          </p>
          <p>
            <strong>onClose:</strong> Function to be called on modal close,
            enhancing keyboard navigation like pressing the Escape key.
          </p>
          <p>
            <strong>aria-modal:</strong> "true" - Marks your modal as an active
            dialog, ensuring it's treated as a focus trap by screen readers.
          </p>
        </div>
        <p>
          Beyond these, Ela Spark UI Modals supports extensive customization
          with additional props, allowing for a seamless blend of style and
          accessibility.
        </p>
      </div>

      {/* Code Examples and Demonstrations */}
      <div className="text-container">
        <h2>Seamless Integration with Real-World Examples</h2>
        <p>
          Discover the ease of integrating accessible modals into your projects.
          Below are practical examples that demonstrate the versatility and
          accessibility of Ela Spark UI Modals in action.
        </p>
      </div>

      <div className="modal-code-examples">
        <h2>Example: Accessible Modal</h2>
        <ButtonContainer align="center">
          <Button onClick={handleShowCode}>
            {loading ? "Chargement..." : "Show Code"}
          </Button>
        </ButtonContainer>
        <div>
          {showCode && (
            <Suspense fallback={<div>Chargement...</div>}>
              <CodeBlockLazy>
                {`<Modal
  role="dialog"
  aria-labelledby="modalTitle"
  aria-describedby="modalDescription"
  aria-modal="true"
  onClose={handleCloseModal}
  size="m-l"
  styleType="style-1"
  centered
>
  <h1 id="modalTitle">Modal Title</h1>
  <p id="modalDescription">
    This paragraph contains the description of the modal which will be read by
    screen readers.
  </p>
  {/* Additional content here */}
</Modal>;`}
              </CodeBlockLazy>
              <p>
                This example showcases a basic accessible modal with essential
                ARIA attributes and keyboard navigation support.
              </p>
            </Suspense>
          )}
        </div>
      </div>

      {/* Additional Accessibility Features */}
      <div className="text-container">
        <h2>Empowering Accessibility Beyond the Basics</h2>
        <p>
          Ela Spark UI Modals doesn't just meet basic accessibility standards;
          it goes above and beyond. With advanced features and customization
          options, you can create an inclusive experience that caters to all
          users. Stay tuned for more updates and enhancements as we continue to
          push the boundaries of accessible web design.
        </p>
      </div>
    </div>
  );
};

export default ModalAccessibility;