import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BarsIcon } from "../../assets/img/bars-solid.svg";

import logo from "../../assets/img/logo.webp";
import "./Header.css";

const Header = ({ onToggleSidebar }) => {
  return (
    <header className="home-header header-neon-shadow">
      <button className="sidebar-toggle-button" onClick={onToggleSidebar}>
        <BarsIcon className="bars-icon" />
      </button>
      <Link to="/" className="home-logo-link">
        <img src={logo} alt="Ela Spark UI Logo" className="home-logo" />
        <span className="home-logo-text">Ela Spark UI</span>
      </Link>
    </header>
  );
};

export default Header;