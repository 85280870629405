import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ModalExample from "../../components/ModalExample";
import "./ModalsPage.css";

const ModalAnimations = () => {
  const [animation, setAnimation] = useState("fadeIn");

  return (
    <div className="home-container">
      <Helmet>
        <title>Modal Animations - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/modals/modal-animations"/>
        <meta
          name="description"
          content="Ela Spark UI Modals offers sophisticated animation options to make your modals visually engaging and enhance user experience."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, modal animations, dynamic modals, UI animations, engaging user interface, animated modals, React modal animations, web design, creative modal design, frontend development, UI/UX design, interactive modals, modal effects, animation props, neon animations"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/modals/modal-animations"
        />
        <meta property="og:title" content="Modal Animations - Ela Spark UI" />
        <meta
          property="og:description"
          content="Ela Spark UI Modals offers sophisticated animation options to make your modals visually engaging and enhance user experience."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/modals/modal-animations"
        />
        <meta
          property="twitter:title"
          content="Modal Animations - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Ela Spark UI Modals offers sophisticated animation options to make your modals visually engaging and enhance user experience."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Bringing Life to Your Modals with Ela Spark UI Modals</h1>
        <p>
          Animations can transform the user experience from ordinary to
          extraordinary. With Ela Spark UI Modals, you get a suite of
          sophisticated animation options to make your modals not just
          functional, but also visually engaging.
        </p>
      </div>

      {/* Animation Options Overview */}
      <div className="prop-container">
        <h2>Animating Your Modals</h2>
        <p>
          Choose from a wide range of animations to add a dynamic flair to your
          modals. Easy to implement and customize, these animations enhance the
          user experience and draw attention to your modal content.
        </p>
        <div className="prop-description">
          <h3>Available Animation Props</h3>
          <p>
            <strong>fadeIn :</strong> Gradually makes the modal appear, creating
            a smooth entry.
          </p>
          <p>
            <strong>fadeOut :</strong> Gradually makes the modal disappear,
            providing a seamless exit.
          </p>
          <p>
            <strong>flash :</strong> Alternates modal opacity for a noticeable
            flash effect.
          </p>
          <p>
            <strong>softBlink :</strong> Gently toggles the modal's visibility,
            creating a subtle blinking effect.
          </p>
          <p>
            <strong>gradualDisappear :</strong> Slowly fades the modal away,
            ideal for gentle transitions.
          </p>
          <p>
            <strong>gradualAppear :</strong> Slowly brings the modal into view,
            perfect for non-intrusive entrances.
          </p>
          <p>
            <strong>sparkle :</strong> Creates a quick twinkling effect, adding
            a touch of magic.
          </p>
          <p>
            <strong>rotate :</strong> Spins the modal around its center for a
            dynamic presence.
          </p>
          <p>
            <strong>swell :</strong> Enlarges the modal, making it more
            prominent on the screen.
          </p>
          <p>
            <strong>shrink :</strong> Minimizes the modal's size, adding a
            playful contraction effect.
          </p>
          <p>
            <strong>neon-purple :</strong> Violet neon animation, evoking a
            starry night.
          </p>
          <p>
            <strong>neon-blue :</strong> Calm blue gradient, perfect for a
            tranquil effect.
          </p>
          <p>
            <strong>neon-green :</strong> Vibrant green neon for a lively and
            fresh look.
          </p>
          <p>
            <strong>neon-red :</strong> Bold red neon for a strong visual
            impact.
          </p>
          <p>
            <strong>neon-orange :</strong> Warm orange glow for a cozy, inviting
            feel.
          </p>
          <p>
            <strong>neon-pink :</strong> Playful pink neon, ideal for a
            whimsical touch.
          </p>
          <p>
            <strong>neon-yellow :</strong> Bright yellow neon for an optimistic
            vibe.
          </p>
          <p>
            <strong>border-neon :</strong> Multi-colored border for a dynamic
            appearance.
          </p>
          <p>
            <strong>border-neon-blue-purple :</strong> Alternating blue and
            purple for a stylish look.
          </p>
          <p>
            <strong>border-neon-red-orange :</strong> Vibrant red and orange
            border for an energetic feel.
          </p>
          <p>
            <strong>border-neon-green-yellow :</strong> Refreshing green and
            yellow border for a lively style.
          </p>
          <p>
            <strong>border-neon-rainbow :</strong> Rainbow border cycling
            through various hues.
          </p>
        </div>
      </div>

      {/* Code Examples and Demonstrations */}
      <div>
        <ModalExample
          titleText="Animations"
          inputType="animation"
          defaultValue={animation}
          size="m-l"
          onChange={(e) => setAnimation(e.target.value)}
          modalProp="animation"
          modalPropValue={animation}
        />
      </div>

      {/* Conclusion and Encouragement to Explore */}
      <div className="text-container">
        <h2>Unleash Your Creativity with Ela Spark UI Modals</h2>
        <p>
          Whether you're looking for subtle movements or dynamic entrances, Ela
          Spark UI Modals provides you with the tools to bring your modals to
          life. Explore the possibilities and find the perfect animation to suit
          your project's tone and style. Your journey towards creating engaging
          and interactive user interfaces begins here with Ela Spark UI Modals.
        </p>
      </div>
    </div>
  );
};

export default ModalAnimations;