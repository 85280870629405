import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ReactComponent as ChevronDownIcon } from "../../assets/img/chevron-down-solid.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/img/chevron-right-solid.svg";
import "./FAQ.css";
import faqData from "../../data/data";

const FAQ = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="faq-container">
      <Helmet>
        <title>FAQ - Ela Spark UI</title>
        <link rel="canonical" href="https://www.elaspark-ui.dev/faq" />
        <meta
          name="description"
          content="Find answers to frequently asked questions about Ela Spark UI, covering topics from how to use our UI components to customization and support."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, FAQ, frequently asked questions, UI components, customization, support, user guide"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.elaspark-ui.dev/faq" />
        <meta property="og:title" content="FAQ - Ela Spark UI" />
        <meta
          property="og:description"
          content="Find answers to frequently asked questions about Ela Spark UI, covering topics from how to use our UI components to customization and support."
        />
        <meta
          property="og:image"
          content="https://imgur.com/link-to-faq-image"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="https://www.elaspark-ui.dev/faq"
        />
        <meta property="twitter:title" content="FAQ - Ela Spark UI" />
        <meta
          property="twitter:description"
          content="Find answers to frequently asked questions about Ela Spark UI, covering topics from how to use our UI components to customization and support."
        />
        <meta
          property="twitter:image"
          content="https://imgur.com/link-to-faq-image"
        />
      </Helmet>
      <h1>Frequently Asked Questions</h1>
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <h3>{faq.question}</h3>
            {openFAQ === index ? (
              <ChevronDownIcon className="chevron-icon" />
            ) : (
              <ChevronRightIcon className="chevron-icon" />
            )}
          </div>

          {openFAQ === index && <div className="faq-answer">{faq.answer}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
