import React from "react";
import { Helmet } from "react-helmet";
import "./TermsOfUse.css";

function TermsOfUse() {
  return (
    <main className="terms-container">
      <Helmet>
        <title>Terms of Use - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/terms-of-use" />
        <meta
          name="description"
          content="Read the Terms of Use of Ela Spark UI to understand the rules and guidelines for using our website and services."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, Terms of Use, website terms, service guidelines, usage rules, legal terms, user agreement"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/terms-of-use"
        />
        <meta property="og:title" content="Terms of Use - Ela Spark UI" />
        <meta
          property="og:description"
          content="Read the Terms of Use of Ela Spark UI to understand the rules and guidelines for using our website and services."
        />
        <meta
          property="og:image"
          content="https://imgur.com/link-to-terms-of-use-image"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/terms-of-use"
        />
        <meta property="twitter:title" content="Terms of Use - Ela Spark UI" />
        <meta
          property="twitter:description"
          content="Read the Terms of Use of Ela Spark UI to understand the rules and guidelines for using our website and services."
        />
        <meta
          property="twitter:image"
          content="https://imgur.com/link-to-terms-of-use-image"
        />
      </Helmet>

      <div className="terms-header">
        <h1>Terms of Use for Ela Spark UI</h1>
      </div>

      <div className="terms-sections-container">
        <section className="terms-section" id="intro">
          <h2>Introduction</h2>
          <p>
            These Terms of Use ("Terms") govern your access to and use of this
            website, hosted and provided by [Ela Spark UI] ("Ela Spark UI",
            "we", or "us"), ("Website") and any services offered through the
            Website ("Services").
          </p>
          <p>
            The Terms constitute a binding legal agreement between you and us,
            so it is important that you read them carefully.
          </p>
          <p>
            <span>
              Please note, these Terms apply exclusively to this Website and do
              not extend to:
            </span>
          </p>
          <p>
            The use of open source code, documentation, or specifications
            available on platforms like GitHub, which are subject to the terms
            of the respective open source licenses; Contributions, issues, and
            other interactions associated with open source projects on platforms
            like GitHub, which are governed by the terms and conditions of those
            platforms;
          </p>
          <p>or</p>
          <p>
            The use of any other websites, services, or products from us, which
            are governed by their respective terms and conditions. We reserve
            the right to modify these Terms or any features of the Website or
            the Services at any time. Such changes or modifications will become
            effective immediately upon being posted on our Website. By browsing
            this Website and/or using the Services, you agree to these Terms,
            and you acknowledge your acceptance of any modifications by
            continuing to use the Website after such modifications are posted.
          </p>
          <p>
            If you disagree with these Terms, please refrain from accessing or
            using the Website.
          </p>
          <p>For any inquiries, please contact us at contact@elaspark-ui.dev</p>
        </section>

        <section className="terms-section" id="usage">
          <h2>Usage of this Website</h2>
          <p>
            This Website is provided to facilitate a better understanding and
            usage of our services, including Ela Spark UI's modal, button, and
            upcoming features like forms, lightboxes, and stories. In return, we
            require you to adhere to <span>the following commitments:</span>
          </p>
          <p>
            <span>(a) Eligibility to Use the Website</span>
          </p>
          <p>
            The Website is intended for a general audience, but not for
            individuals who are minors in their jurisdiction, unless consented
            to by a parent or guardian. Access to the Website is prohibited
            under certain applicable laws.
          </p>
          <p>
            <span>(b) Acceptable Use</span>
          </p>
          <p>
            You agree to use the Website in compliance with these Terms and not
            to engage in unlawful, misleading, discriminatory, or fraudulent
            activities, or infringe on anyone's rights, including intellectual
            property rights. You must refrain from spreading viruses, malicious
            code, or anything that could disrupt or damage the functioning and
            appearance of the Website or Services.
          </p>
          <p>
            <span>(c) Access to the Website</span>
          </p>
          <p>
            The Website is provided free of charge and the Services are offered
            solely for informational purposes. We do not guarantee the accuracy,
            timeliness, or completeness of any content provided and are not
            liable for any reliance on this content. Third-Party Content The
            Website or Services may link to third-party content. We are not
            responsible for the content, terms, or policies of third-party
            websites and platforms.
          </p>
        </section>

        <section className="terms-section" id="third-party-content">
          <h2>Third-Party Content</h2>
          <p>The Website or Services may link to third-party content...</p>
        </section>

        <section className="terms-section" id="limitation-liability">
          <h2>Limitation of Liability</h2>
          <p>
            While we strive to offer the best Website and Services, they are
            provided "as is" without any guarantees. We disclaim all warranties
            to the extent permitted by law. Our liability is limited to the
            fullest extent permitted by law, and we will not be liable for
            indirect or consequential damages.
          </p>
        </section>

        <section className="terms-section" id="privacy">
          <h2>Privacy</h2>
          <p>
            Our Privacy Policy and Cookie Policy outline our data processing
            practices and cookie usage. Please review these for more
            information.
          </p>
        </section>

        <section className="terms-section" id="general-provisions">
          <h2>General Provisions</h2>
          <p>
            These Terms constitute the entire agreement between you and Ela
            Spark UI. They supersede any prior agreements. Amendments or waivers
            to these Terms must be in writing and signed by us. You may not
            transfer your rights under these Terms without our consent. No joint
            venture, partnership, employment, or agency relationship exists
            between you and Ela Spark UI as a result of these Terms. These Terms
            do not confer any third-party beneficiary rights. We reserve all
            rights not expressly granted to you.
          </p>
        </section>
      </div>
    </main>
  );
}

export default TermsOfUse;
