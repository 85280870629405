import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./NotFoundPage.css";

import notFoundImage from "../../assets/img/error404.webp";

function NotFoundPage() {
  return (
    <div className="not-found-container">
      <Helmet>
        <title>Page Not Found - Ela Spark UI</title>
        <meta
          name="description"
          content="Sorry, the page you're looking for does not exist. Please try navigating back to the homepage or using the search feature."
        />
        <link rel="canonical" href="http://www.elaspark-ui.dev/not-found" />
      </Helmet>
      <img
        src={notFoundImage}
        alt="Whoops! We can't find that page..."
        className="not-found-image"
      />
      <p className="not-found-text">
        Sorry, the page you're looking for does not exist.
      </p>
      <Link to="/" className="not-found-link">
        Go to the Home Page
      </Link>
    </div>
  );
}

export default NotFoundPage;