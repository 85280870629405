import React, { useState, Suspense } from "react";
import { Modal, Button, ButtonContainer } from "elaspark-ui";

import "../pages/ModalsPage/ModalsPage.css";
const ModalCodeExample = React.lazy(() => import("./ModalCodeExample"));

const ModalExample = ({ titleText, inputType }) => {
  const [showCode, setShowCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const [size, setSize] = useState("m-xl");
  const [styleType, setStyleType] = useState("style-4");
  const [backdropStyle, setBackdropStyle] = useState("dark-opaque");
  const [animation, setAnimation] = useState("fadeIn");

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleShowCode = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCode(true);
      setLoading(false);
    }, 1000);
  };

  const generateInput = () => {
    switch (inputType) {
      case "size":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter size"
            value={size}
            onChange={(e) => setSize(e.target.value)}
          />
        );
      case "styleType":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter styleType"
            value={styleType}
            onChange={(e) => setStyleType(e.target.value)}
          />
        );
      case "backdropStyle":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter backdropStyle"
            value={backdropStyle}
            onChange={(e) => setBackdropStyle(e.target.value)}
          />
        );
      case "animation":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter animation"
            value={animation}
            onChange={(e) => setAnimation(e.target.value)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-code-examples">
      <h2>Explore Dynamic {titleText}</h2>
      <p className="p-style">
        Discover the impact of {titleText.toLowerCase()} in enhancing user
        experience. Enter a {titleText.toLowerCase()} name and see it in action.
      </p>
      <div className="input-container">{generateInput()}</div>

      <ButtonContainer align="center">
        <Button styleType="btn-8" onClick={openModal}>
          Open Modal
        </Button>
        <Button onClick={handleShowCode}>
          {loading ? "Chargement..." : "Show Code"}
        </Button>
      </ButtonContainer>

      {showCode && (
        <Suspense>
          <ModalCodeExample
            size={size}
            styleType={styleType}
            backdropStyle={backdropStyle}
            animation={animation}
          />
        </Suspense>
      )}

      {isModalOpen && (
        <Modal
          size={size}
          styleType={styleType}
          backdropStyle={backdropStyle}
          animation={animation}
          onClose={closeModal}
          showCloseButton={true}
          closeAlign="right"
          closeButtonStyleType="btn-8"
          closeButtonAnimation="wave"
          closeButtonBorderStyle="b-b-3"
          centered
        >
          <h1 style={{ textAlign: "center" }}>Easy Ela Modals</h1>
          <p style={{ textAlign: "center" }}>
            Ela Spark UI Modals brings a fresh wave of creativity and
            functionality to modal design. Our platform is at the forefront of
            offering dynamic, visually appealing, and user-friendly modals that
            seamlessly integrate with your digital landscape.
          </p>
          <p style={{ textAlign: "center" }}>
            With Ela Spark UI Modals, you're not just building interfaces;
            you're crafting experiences. Our modals are designed to enhance user
            engagement through their fluid animations, adaptable styles, and
            responsive layouts. Unlock the full potential of your projects with
            our cutting-edge modal solutions.
          </p>
          <ButtonContainer align="center">
            <Button onClick={closeModal}>Close</Button>
          </ButtonContainer>
        </Modal>
      )}
    </div>
  );
};

export default ModalExample;
