import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Button, ButtonContainer } from "elaspark-ui";
import "../ModalsPage/ModalsPage.css";

const CodeBlockLazy = React.lazy(() => import("../../components/CodeBlock"));

const ButtonEssentials = () => {
  const [showCodeExample1, setShowCodeExample1] = useState(false);
  const [loadingExample1, setLoadingExample1] = useState(false);

  const [showCodeExample2, setShowCodeExample2] = useState(false);
  const [loadingExample2, setLoadingExample2] = useState(false);

  const handleShowCodeExample1 = () => {
    setLoadingExample1(true);
    setTimeout(() => {
      setShowCodeExample1(true);
      setLoadingExample1(false);
    }, 1000);
  };

  const handleShowCodeExample2 = () => {
    setLoadingExample2(true);
    setTimeout(() => {
      setShowCodeExample2(true);
      setLoadingExample2(false);
    }, 1000);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Button Design Essentials - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/buttons/button-essencials"/>
        <meta
          name="description"
          content="Learn the foundational elements of button design with Ela Spark UI, from basic styles to advanced customization and alignment."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, button design, UI basics, button styling, button alignment, customizable buttons, button containers, UI components, web design, React buttons, user interface design, button sizes, UI/UX, frontend development, creative button design, button customization"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/buttons/button-essencials"
        />
        <meta
          property="og:title"
          content="Button Design Essentials - Ela Spark UI"
        />
        <meta
          property="og:description"
          content="Learn the foundational elements of button design with Ela Spark UI, from basic styles to advanced customization and alignment."
        />
        <meta
          property="og:image"
          content="https://imgur.com/link-to-button-essentials-image"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/buttons/button-essencials"
        />
        <meta
          property="twitter:title"
          content="Button Design Essentials - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Learn the foundational elements of button design with Ela Spark UI, from basic styles to advanced customization and alignment."
        />
        <meta
          property="twitter:image"
          content="https://imgur.com/link-to-button-essentials-image"
        />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>
          Foundational Elements of Button Design with Ela Spark UI Buttons
        </h1>
        <p>
          Master the basics of button design with Ela Spark UI Buttons. From
          fundamental styles to button containers, grasp the essentials needed
          to create functional and aesthetically pleasing buttons.
        </p>
      </div>

      {/* Button Containers and Alignment */}
      <div className="prop-container">
        <h2>Organizing Buttons with ButtonContainer</h2>
        <p>
          Group and align your buttons effectively with the ButtonContainer
          component. Utilize alignment options to position your buttons for
          optimal layout and design.
        </p>
        <div className="prop-description">
          <h3>ButtonContainer Alignment Options</h3>
          <p>
            <strong>align="right":</strong> Aligns buttons to the right within
            the container.
          </p>
          <p>
            <strong>align="center":</strong> Centers buttons within the
            container.
          </p>
          <p>
            <strong>align="left":</strong> Aligns buttons to the left within the
            container.
          </p>
          <p>
            <strong>align="auto":</strong> Automatically spaces buttons evenly
            within the container.
          </p>
        </div>
      </div>

      {/* Button Sizes and Usage */}
      <div className="prop-container">
        <h2>Button Sizes: Tailoring to Your Needs</h2>
        <p>
          Choose the perfect size for your buttons to fit the design and
          functionality of your application. Ela Spark UI Buttons provides a
          range of size options for customization.
        </p>
        <div className="prop-description">
          <h3>Available Button Sizes</h3>
          <p>
            <strong>b-s:</strong> Small - Ideal for compact spaces.
          </p>
          <p>
            <strong>b-m:</strong> Medium - A balanced size for general use.
          </p>
          <p>
            <strong>b-l:</strong> Large - For prominent call-to-action buttons.
          </p>
          <p>
            <strong>b-xl:</strong> Extra Large - Makes a statement with size.
          </p>
        </div>
      </div>

      {/* Example: Button Container with Aligned Buttons */}
      <div className="modal-code-examples">
        <h2>Example: Button Container and Alignment</h2>
        <ButtonContainer align="center">
          <Button onClick={handleShowCodeExample1}>
            {loadingExample1 ? "Chargement..." : "Show Code"}
          </Button>
        </ButtonContainer>
        <div>
          {showCodeExample1 && (
            <Suspense fallback={<div>Chargement...</div>}>
              <CodeBlockLazy>
                {`<ButtonContainer align="center">
  <Button align="right" size="b-m" styleType="btn-10">Close</Button>
  <Button align="left" size="b-m" styleType="btn-2">Action</Button>
</ButtonContainer>`}
              </CodeBlockLazy>
            </Suspense>
          )}
          <p>
            This example illustrates the use of ButtonContainer to group buttons
            together, with individual alignment options for each button.
          </p>
        </div>
      </div>

      {/* Example: Fully Styled and Accessible Button */}
      <div className="modal-code-examples">
        <h2>Example: A Complete Button Setup</h2>
        <div>
          <ButtonContainer align="center">
            <Button onClick={handleShowCodeExample2}>
              {loadingExample2 ? "Chargement..." : "Show Code"}
            </Button>
          </ButtonContainer>
          {showCodeExample2 && (
            <Suspense fallback={<div>Chargement...</div>}>
              <CodeBlockLazy>
                {`<Button 
  styleType="btn-5" 
  borderStyle="b-b-3" 
  animation="pulse" 
  aria-label="Example Button" 
  role="button" 
  tabIndex={0}
  >
  Click Me
</Button>`}
              </CodeBlockLazy>
            </Suspense>
          )}
          <p>
            This example demonstrates a fully styled and accessible button,
            combining style, animation, and accessibility features for an
            optimal user experience.
          </p>
        </div>
      </div>

      {/* Conclusion and Encouragement to Experiment */}
      <div className="text-container">
        <h2>Unleash Your Creativity with Ela Spark UI Buttons</h2>
        <p>
          Now equipped with the essential knowledge of button design, experiment
          with different styles, sizes, and alignments to create buttons that
          perfectly match your application's needs. Explore and innovate with
          Ela Spark UI Buttons.
        </p>
      </div>
    </div>
  );
};

export default ButtonEssentials;