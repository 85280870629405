import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ButtonExample from "../../components/ButtonExample";
import "../ModalsPage/ModalsPage.css";

const ButtonStyle = () => {
  const [styleType, setStyleType] = useState("btn-1");

  return (
    <div className="home-container">
      <Helmet>
        <title>Button Styles - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/buttons/button-styles"/>
        <meta
          name="description"
          content="Discover a wide range of button styles with Ela Spark UI Buttons, from classic to vibrant, each designed to enhance your UI's aesthetic."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, button styles, UI design, custom buttons, stylish buttons, web design, user interface, React components, colorful buttons, design aesthetics, frontend development, UI/UX, button customization, creative styles, vibrant buttons, modern UI"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/buttons/button-styles"
        />
        <meta property="og:title" content="Button Styles - Ela Spark UI" />
        <meta
          property="og:description"
          content="Discover a wide range of button styles with Ela Spark UI Buttons, from classic to vibrant, each designed to enhance your UI's aesthetic."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/buttons/button-styles"
        />
        <meta property="twitter:title" content="Button Styles - Ela Spark UI" />
        <meta
          property="twitter:description"
          content="Discover a wide range of button styles with Ela Spark UI Buttons, from classic to vibrant, each designed to enhance your UI's aesthetic."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Styling with Flair: Button Styles by Ela Spark UI Buttons</h1>
        <p>
          Ela Spark UI Buttons offers a rich palette of button styles to
          complement your UI. From classic to vibrant, each style is crafted to
          add a unique aesthetic touch.
        </p>
      </div>

      {/* Style Options Overview */}
      <div className="prop-container">
        <h2 className="fade-in">Diverse Button Styles</h2>
        <div className="prop-description">
          {/* List of button styles */}
          <p>
            <strong>btn-1 (Blue):</strong> A serene blue for a professional
            look.
          </p>
          <p>
            <strong>btn-2 (Green):</strong> Green for eco-friendly and positive
            vibes.
          </p>
          <p>
            <strong>btn-3 (Yellow):</strong> Bright yellow for a cheerful touch.
          </p>
          <p>
            <strong>btn-4 (Teal):</strong> Teal for a modern, trendy appearance.
          </p>
          <p>
            <strong>btn-5 (Red):</strong> Bold red for urgent or important
            actions.
          </p>
          <p>
            <strong>btn-6 (Orange):</strong> Orange for creativity and
            enthusiasm.
          </p>
          <p>
            <strong>btn-7 (Purple):</strong> Royal purple for a touch of
            elegance.
          </p>
          <p>
            <strong>btn-8 (Mint Green):</strong> Refreshing mint for a light,
            airy feel.
          </p>
          <p>
            <strong>btn-9 (Indigo):</strong> Deep indigo for sophistication and
            depth.
          </p>
          <p>
            <strong>btn-10 (Pink):</strong> Vibrant pink for a fun, playful
            flair.
          </p>
        </div>
      </div>

      {/* Interactive Button Example */}
      <div>
        <ButtonExample
          titleText="Button Styles"
          inputType="styleType"
          onChange={(e) => setStyleType(e.target.value)}
          styleType={styleType}
        />
      </div>

      {/* Encouragement to Explore */}
      <div className="text-container">
        <h2>Craft Your Aesthetic with Ela Spark UI Buttons</h2>
        <p>
          Explore the range of button styles to find the perfect match for your
          project. Ela Spark UI Buttons empowers you to design interfaces that
          resonate with your brand and audience.
        </p>
      </div>
    </div>
  );
};

export default ButtonStyle;