import React, { useState, Suspense } from "react";
import { Button, ButtonContainer } from "elaspark-ui";
import "../pages/ModalsPage/ModalsPage.css";

const ButtonCodeExample = React.lazy(() => import("./ButtonCodeExample"));

const ButtonExample = ({ titleText, inputType }) => {
  const [styleType, setStyleType] = useState("btn-1");
  const [borderStyle, setBorderStyle] = useState("b-b-1");
  const [animation, setAnimation] = useState("wave");

  const handleStyleTypeChange = (e) => setStyleType(e.target.value);
  const handleBorderStyleChange = (e) => setBorderStyle(e.target.value);
  const handleAnimationChange = (e) => setAnimation(e.target.value);

  const [showCode, setShowCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShowCode = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCode(true);
      setLoading(false);
    }, 1000);
  };
  const generateInput = () => {
    switch (inputType) {
      case "styleType":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter styleType"
            value={styleType}
            onChange={handleStyleTypeChange}
          />
        );
      case "borderStyle":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter borderStyle"
            value={borderStyle}
            onChange={handleBorderStyleChange}
          />
        );
      case "animation":
        return (
          <input
            className="input-animation-name"
            type="text"
            placeholder="Enter animation"
            value={animation}
            onChange={handleAnimationChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="home-container">
      <div className="modal-code-examples fade-in">
        <h2 className="slide-up">Explore Dynamic {titleText}</h2>
        <p className="p-style fade-in">
          Customize the appearance and feel of Ela Spark UI Buttons
          Modalsbuttons with different {inputType}.
        </p>
        <div className="input-container">{generateInput()}</div>

        <ButtonContainer align="center">
          <Button
            styleType={styleType}
            borderStyle={borderStyle}
            animation={animation}
          >
            Click Me
          </Button>
          <Button onClick={handleShowCode}>
            {loading ? "Chargement..." : "Show Code"}
          </Button>
        </ButtonContainer>

        {showCode && (
          <Suspense>
            <ButtonCodeExample
              styleType={styleType}
              borderStyle={borderStyle}
              animation={animation}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default ButtonExample;