import React from "react";
import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <main className="privacy-container">
      <Helmet>
        <title>Privacy Policy - Ela Spark UI</title>
        <link
          rel="canonical"
          href="http://www.elaspark-ui.dev/privacy-policy"
        />
        <meta
          name="description"
          content="Read the Privacy Policy of Ela Spark UI to understand how we handle your information in connection with our website and services."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, Privacy Policy, information handling, user data protection, personal data, web privacy, data security, legal compliance"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://elaspark-ui.dev/privacy-policy"
        />
        <meta property="og:title" content="Privacy Policy - Ela Spark UI" />
        <meta
          property="og:description"
          content="Read the Privacy Policy of Ela Spark UI to understand how we handle your information in connection with our website and services."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="https://elaspark-ui.dev/privacy-policy"
        />
        <meta
          property="twitter:title"
          content="Privacy Policy - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Read the Privacy Policy of Ela Spark UI to understand how we handle your information in connection with our website and services."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      <div className="privacy-header">
        <h1>Privacy Policy for Ela Spark UI</h1>
      </div>

      <div className="privacy-sections-container">
        <section className="privacy-section" id="preambule">
          <h2>Preambule</h2>
          <p>
            This Privacy Policy outlines the practices of Ela Spark UI ("Ela
            Spark UI", "we", "us", or "our") regarding the handling of your
            information in connection with this website and any services
            provided through it (collectively, the "Services"). At present, our
            website does not collect personal data, but this policy details the
            principles we would adhere to if we start collecting data in the
            future.
          </p>
        </section>

        <section className="privacy-section" id="scope-of-policy">
          <h2>Scope of This Policy</h2>
          <p>
            <span>
              This Privacy Policy applies only to this website and does not
              extend to :
            </span>
          </p>
          <p>
            Use of open source code, documentation, or specifications available
            on platforms like GitHub, governed by their respective open source
            licenses.
          </p>
          <p>
            Interactions on platforms like GitHub, which are subject to their
            terms and conditions.
          </p>
          <p>
            Any other website, service, or product from us, governed by their
            respective terms and conditions.
          </p>
        </section>

        <p></p>
        <p></p>

        <section className="privacy-section" id="information-collection">
          <h2>Information Collection and Use</h2>
          <p>
            As of now, our website does not collect personal information.
            However, should we decide to collect data in the future, it will be
            in line with the following principles:
          </p>
          <p>
            <span>Information You Provide :</span> This may include contact
            details or other information you choose to provide to us.
          </p>
          <p>
            Automatically Collected Information: If we start collecting data
            automatically, it would include standard web analytics information,
            such as browser type, pages visited, and other similar data.
          </p>
        </section>

        <section className="privacy-section" id="policy-changes">
          <h2>Changes to Our Privacy Policy</h2>
          <p>
            We reserve the right to modify this Privacy Policy at any time. Any
            changes will be effective immediately upon posting to the website.
            We will provide notice of significant changes through the website or
            through other appropriate communication channels.
          </p>
        </section>

        <section className="privacy-section" id="use-of-information">
          <h2>Use of Information</h2>
          <p>
            Should we collect information in the future,
            <span>it would be used for :</span>
          </p>
          <p>Improving our Services and your user experience.</p>
          <p>Communicating with you, only if necessary.</p>
          <p>Ensuring the security and integrity of our Services.</p>
        </section>

        <section className="privacy-section" id="sharing-of-information">
          <h2>Sharing of Information</h2>
          <p>
            <span>
              We do not share personal information with third parties except :
            </span>
          </p>
          <p>To comply with legal obligations or protect our rights.</p>
          <p>
            With service providers working on our behalf, under strict
            confidentiality agreements.
          </p>
        </section>

        <section className="privacy-section" id="cookies-and-tracking">
          <h2>Cookies and Tracking Technologies</h2>
          <p>
            Currently, our website does not use cookies or similar tracking
            technologies. If this changes, it will be reflected in an updated
            version of this policy.
          </p>
        </section>

        <section className="privacy-section" id="data-security">
          <h2>Data Security</h2>
          <p>
            We are committed to protecting the security of any information we
            might collect in the future. Appropriate measures will be taken to
            prevent unauthorized access, disclosure, alteration, or destruction
            of data.
          </p>
        </section>

        <section className="privacy-section" id="contact-information">
          <h2>Contact Information</h2>
          <p>
            Contact Information For any questions regarding this Privacy Policy,
            please contact us at [contact@elaspark-ui.dev].
          </p>
        </section>
      </div>
    </main>
  );
}

export default PrivacyPolicy;