import React, { useRef, useEffect } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/vs2015.css";

const CodeBlock = ({ children }) => {
  const codeNode = useRef(null);

  useEffect(() => {
    if (codeNode.current) {
      hljs.highlightElement(codeNode.current);
    }
  }, [children]);

  return (
    <pre>
      <code ref={codeNode} className="hljs">
        {children}
      </code>
    </pre>
  );
};

export default CodeBlock;