import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ButtonExample from "../../components/ButtonExample";
import "../ModalsPage/ModalsPage.css";

const ButtonAnimations = () => {
  const [animation, setAnimation] = useState("");

  return (
    <div className="home-container">
      <Helmet>
        <title>Button Animations - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/buttons/button-animations"/>
        <meta
          name="description"
          content="Explore a variety of engaging animations for buttons with Ela Spark UI, enhancing user interaction and visual appeal."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, button animations, dynamic UI, interactive buttons, CSS animations, animated buttons, web design, user engagement, creative button design, UI/UX, frontend development, React UI library, animation effects, hover effects, UI customization, responsive design"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/buttons/button-animations"
        />
        <meta property="og:title" content="Button Animations - Ela Spark UI" />
        <meta
          property="og:description"
          content="Explore a variety of engaging animations for buttons with Ela Spark UI, enhancing user interaction and visual appeal."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/buttons/button-animations"
        />
        <meta
          property="twitter:title"
          content="Button Animations - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Explore a variety of engaging animations for buttons with Ela Spark UI, enhancing user interaction and visual appeal."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Animating Your Buttons with Ela Spark UI Buttons</h1>
        <p>
          Ela Spark UI Buttons offers a variety of animations to add a dynamic
          touch to your buttons. Experiment with different animations and see
          them in action.
        </p>
      </div>

      {/* Animation Options Overview */}
      <div className="prop-container">
        <h2>Available Button Animations</h2>
        <div className="prop-description">
          {/* List of animations */}
          <p>
            <strong>rotate :</strong> Rotates the button on hover.
          </p>
          <p>
            <strong>pulse :</strong> Creates a pulsing effect on the button.
          </p>
          <p>
            <strong>swing :</strong> Gives a swinging effect to the button.
          </p>
          <p>
            <strong>stretchVertical :</strong> Stretches the button vertically
            on hover.
          </p>
          <p>
            <strong>stretchHorizontal :</strong> Stretches the button
            horizontally on hover.
          </p>
          <p>
            <strong>bounce :</strong> Creates a bouncing effect for the button.
          </p>
          <p>
            <strong>blur :</strong> Applies a blur effect to the button on
            hover.
          </p>
          <p>
            <strong>colorFade :</strong> Gradually changes the background color
            of the button on hover.
          </p>
          <p>
            <strong>wave :</strong> Creates a wave effect on the button on
            hover.
          </p>
          <p>
            <strong>shadow :</strong> Adds a drop shadow to the button when
            hovered.
          </p>
        </div>
      </div>

      {/* Interactive Button Example */}
      <div>
        <ButtonExample
          titleText="Animations"
          inputType="animation"
          onChange={(e) => setAnimation(e.target.value)}
          animation={animation}
        />
      </div>

      {/* Encouragement to Explore */}
      <div className="text-container">
        <h2>Discover the Power of Animation</h2>
        <p>
          Animations add life to your buttons, enhancing user interaction and
          engagement. With Ela Spark UI Buttons, you have the creative freedom
          to choose animations that best fit your design and brand.
        </p>
      </div>
    </div>
  );
};

export default ButtonAnimations;