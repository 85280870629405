import React, { useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Button, ButtonContainer } from "elaspark-ui";
import "./ModalsPage.css";

const CodeBlockLazy = React.lazy(() => import("../../components/CodeBlock"));

const ModalEssentials = () => {
  const [showCodeExample1, setShowCodeExample1] = useState(false);
  const [showCodeExample2, setShowCodeExample2] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowCodeExample1 = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCodeExample1(true);
      setLoading(false);
    }, 1000);
  };

  const handleShowCodeExample2 = () => {
    setLoading(true);
    setTimeout(() => {
      setShowCodeExample2(true);
      setLoading(false);
    }, 1000);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Modal Essentials - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/modals/modal-essentials"/>
        <meta
          name="description"
          content="Learn about the fundamental aspects of modal design with Ela Spark UI Modals, covering everything from shape and size to structure and alignment."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, modal design, UI modals, modal customization, modal shapes, modal sizes, modal structure, React modals, web design, user interface, modal components, modal alignment, frontend development, UI/UX design, modal layouts, modal styling"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/modals/modal-essentials"
        />
        <meta property="og:title" content="Modal Essentials - Ela Spark UI" />
        <meta
          property="og:description"
          content="Learn about the fundamental aspects of modal design with Ela Spark UI Modals, covering everything from shape and size to structure and alignment."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/modals/modal-essentials"
        />
        <meta
          property="twitter:title"
          content="Modal Essentials - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Learn about the fundamental aspects of modal design with Ela Spark UI Modals, covering everything from shape and size to structure and alignment."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Building the Foundation of Your Modals</h1>
        <p>
          Start your journey in crafting modals with Ela Spark UI Modals by
          mastering the essentials. Learn about the fundamental aspects that
          make your modals both functional and appealing.
        </p>
      </div>

      {/* Border Radius and Size Customization */}
      <div className="prop-container">
        <h2>Shape and Size: The First Step in Modal Design</h2>
        <p>
          Customize the shape and size of your modals to fit the context and
          content of your application. Ela Spark UI Modals provides flexible
          options to create the perfect modal.
        </p>
        <div className="prop-description">
          <h3>Border Radius Variations</h3>
          <p>
            <strong>br-none :</strong> For sharp, modern edges.
          </p>
          <p>
            <strong>br-sm :</strong> Subtly rounded corners for a softer look.
          </p>
          <p>
            <strong>br-md :</strong> Moderately curved for a balanced design.
          </p>
          <p>
            <strong>br-lg :</strong> Significantly rounded for a friendly feel.
          </p>
          <p>
            <strong>br-round :</strong> Circular shape for a unique modal style.
          </p>
        </div>
        <div className="prop-description">
          <h3>Modal Sizes for Every Need</h3>
          <p>
            <strong>m-xxs :</strong> Extra extra small, perfect for very concise
            content or simple alerts.
          </p>
          <p>
            <strong>m-xs :</strong> Extra small, ideal for brief messages or
            small forms.
          </p>
          <p>
            <strong>m-s :</strong> Compact size for minimal content.
          </p>
          <p>
            <strong>m-m :</strong> Medium size for balanced content display.
          </p>
          <p>
            <strong>m-l :</strong> Large size for detailed content or forms.
          </p>
          <p>
            <strong>m-xl :</strong> Extra-large for extensive content or complex
            layouts.
          </p>
          <p>
            <strong>m-xxl :</strong> Extra extra large, suitable for
            comprehensive interfaces or extensive data presentation.
          </p>
        </div>
      </div>

      {/* Structure and Alignment */}
      <div className="prop-container">
        <h2>Structuring Your Modals</h2>
        <p>
          Design the layout of your modals with predefined classes. From headers
          to footers, create a structured and engaging modal interface.
        </p>
        <div className="prop-description">
          <h3>Modal Components</h3>
          <p>
            <strong>.modal-header :</strong> Distinguish your modal with a
            dedicated header.
          </p>
          <p>
            <strong>.modal-logo :</strong> Incorporate branding with a logo or
            image.
          </p>
          <p>
            <strong>.modal-title :</strong> Clearly display your modal's purpose
            with a title.
          </p>
          <p>
            <strong>.modal-content :</strong> The main body for your modal's
            content.
          </p>
        </div>
        <div className="prop-description">
          <h3>Content Alignment</h3>
          <p>
            <strong>.left, .center, .right:</strong> Align your modal's content
            to match your design and readability preferences.
          </p>
        </div>
      </div>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Mastering Modal Customization with Ela Spark UI Modals</h1>
        <p>
          Dive into the versatility of Ela Spark UI Modals and discover how to
          tailor modals to your specific needs. From basic setups to advanced
          customizations, learn how to effectively utilize modals in your
          application.
        </p>
      </div>

      {/* Example 1: Using 'title' and 'paragraph' Properties */}
      <div className="modal-code-examples">
        <h2>Example: Structured Modal with Title and Paragraph Properties</h2>
        <div>
          <ButtonContainer align="center">
            <Button onClick={handleShowCodeExample1}>
              {loading ? "Chargement..." : "Show Code"}
            </Button>
          </ButtonContainer>
          {showCodeExample1 && (
            <Suspense fallback={<div>Chargement...</div>}>
              <CodeBlockLazy>
                {`<Modal
  size="m-l"
  styleType="style-1"
  backdropStyle="dark-opaque"
  borderRadius="br-md"
  animation="fadeIn"
  centered
  logoSrc="/path/to/logo.png"
  ariaLabelledBy="modalTitle"
  onClose={closeModal}
  title={{ text: "Your Modal Title", size: "t-2", align: "center" }}
  paragraph={{ text: "Your modal content here.", size: "p-2", align: "left" }}
>
  {/* Additional content here */}
</Modal>`}
              </CodeBlockLazy>
              <p>
                This example demonstrates a modal using the 'title' and
                'paragraph' properties for a streamlined, cohesive design. Ideal
                for quick setups where content structure is key.
              </p>
            </Suspense>
          )}
        </div>
      </div>

      {/* Example 2: Using Custom HTML Tags */}
      <div className="modal-code-examples">
        <h2>Example: Custom HTML Modal with Advanced Styling</h2>
        <div>
          <ButtonContainer align="center">
            <Button onClick={handleShowCodeExample2}>
              {loading ? "Chargement..." : "Show Code"}
            </Button>
          </ButtonContainer>
          {showCodeExample2 && (
            <Suspense fallback={<div>Chargement...</div>}>
              <CodeBlockLazy>
                {`<Modal
  size="m-l"
  styleType="style-1"
  backdropStyle="dark-opaque"
  borderRadius="br-md"
  animation="fadeIn"
  centered
  logoSrc="/path/to/logo.png"
  ariaLabelledBy="modalTitle"
  onClose={closeModal}
>
  <div className="modal-header" style={{ width: '70%' }}>
    <img src="/path/to/logo.png" className="modal-logo" style={{ width: '30%' }} alt="Logo" />
    <h1 className="modal-title t-2 center">Your Custom Modal Title</h1>
  </div>
  <div className="modal-content">
    <p className="modal-paragraph p-2 left">Your custom modal content here.</p>
    {/* Additional custom HTML elements here */}
  </div>
</Modal>`}
              </CodeBlockLazy>
              <p>
                This example illustrates how to use custom HTML tags for greater
                flexibility and personalization. It's ideal for designs that
                require unique layouts and customized content styles.
              </p>
            </Suspense>
          )}
        </div>
      </div>

      {/* Conclusion */}
      <div className="text-container">
        <h2>Start Designing with Confidence</h2>
        <p>
          With these essentials at your fingertips, you're now ready to build
          engaging and effective modals with Ela Spark UI Modals. Embrace
          creativity and functionality in your modal designs.
        </p>
      </div>
    </div>
  );
};

export default ModalEssentials;