import React, { useState } from "react";

import ButtonExample from "../../components/ButtonExample";
import { Helmet } from "react-helmet";
import "../ModalsPage/ModalsPage.css";

const ButtonBorders = () => {
  const [borderStyle, setBorderStyle] = useState("b-b-1");

  return (
    <div className="home-container">
      <Helmet>
        <title>Button Border Styles - Ela Spark UI</title>
        <link rel="canonical" href="http://www.elaspark-ui.dev/buttons/button-borders"/>
        <meta
          name="description"
          content="Explore a range of creative border styles for buttons with Ela Spark UI, enhancing your UI with diverse and customizable designs."
        />
        <meta
          name="keywords"
          content="Ela Spark UI, button borders, customizable borders, UI design, creative button styles, border styles, CSS border, interactive UI, web design, modern UI, button customization, frontend development, React UI library, unique button designs, stylish borders, UI/UX design, innovative borders"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content="http://www.elaspark-ui.dev/buttons/button-borders"
        />
        <meta
          property="og:title"
          content="Button Border Styles - Ela Spark UI"
        />
        <meta
          property="og:description"
          content="Explore a range of creative border styles for buttons with Ela Spark UI, enhancing your UI with diverse and customizable designs."
        />
        <meta property="og:image" content="https://imgur.com/mIUQ6dO" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary" />
        <meta
          property="twitter:url"
          content="http://www.elaspark-ui.dev/buttons/button-borders"
        />
        <meta
          property="twitter:title"
          content="Button Border Styles - Ela Spark UI"
        />
        <meta
          property="twitter:description"
          content="Explore a range of creative border styles for buttons with Ela Spark UI, enhancing your UI with diverse and customizable designs."
        />
        <meta property="twitter:image" content="https://imgur.com/mIUQ6dO" />
      </Helmet>
      {/* Introduction and Overview */}
      <div className="text-container">
        <h1>Styling Buttons with Creative Borders</h1>
        <p>
          Add a distinctive touch to your buttons with Ela Spark UI Buttons'
          diverse border styles. From subtle outlines to bold frames, discover
          how different borders can change the appearance and feel of your
          buttons.
        </p>
      </div>

      {/* Border Style Options Overview */}
      <div className="prop-container">
        <h2>Customizable Border Styles</h2>
        <div className="prop-description">
          {/* List of border styles */}
          <p>
            <strong>b-b-1 :</strong> Solid border for a classic look.
          </p>
          <p>
            <strong>b-b-2 :</strong> Dotted border for a playful effect.
          </p>
          <p>
            <strong>b-b-3 :</strong> Dashed border for a unique style.
          </p>
          <p>
            <strong>b-b-4 :</strong> Grooved border for a 3D appearance.
          </p>
          <p>
            <strong>b-b-5 :</strong> Inset border for a pressed look.
          </p>
          <p>
            <strong>b-b-6 :</strong> Outset border for a raised effect.
          </p>
          <p>
            <strong>b-b-7 :</strong> Double border for a layered look.
          </p>
          <p>
            <strong>b-b-8 :</strong> Ridge border for a textured, embossed
            appearance.
          </p>
          <p>
            <strong>b-b-9 :</strong> Shadow border for added depth and
            dimension.
          </p>
          <p>
            <strong>b-b-10 :</strong> Wavy border for a fun and quirky style.
          </p>
        </div>
      </div>

      {/* Interactive Button Example */}
      <div>
        <ButtonExample
          titleText="Borders"
          inputType="borderStyle"
          onChange={(e) => setBorderStyle(e.target.value)}
          borderStyle={borderStyle}
        />
      </div>

      {/* Encouragement to Explore */}
      <div className="text-container">
        <h2>Enhance Your Designs with Border Styles</h2>
        <p>
          Experiment with our extensive range of border styles to find the
          perfect match for your design. With Ela Spark UI Buttons, the
          possibilities are endless, allowing you to create buttons that truly
          stand out.
        </p>
      </div>
    </div>
  );
};

export default ButtonBorders;